import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { List, InfoIcon } from '../components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <p>{`SmartVault supports two forms of authentication: autonomous authentication using a public/private key pair, and pin-based authentication.`}<br /><br />{`
You can get more information about the authorization methods on the `}<a parentName="p" {...{
        "href": "/api/before-you-begin"
      }}>{`before you begin docs`}</a>{`.`}<br /><br />{`
Through the authorization documentation, you'll notice two kinds of tokens are mentioned: the client token and the delegation token.`}<br />{`
In most cases, you will use the delegation token to authenticate. For `}<a parentName="p" {...{
        "href": "/api/user-accounts/introduction"
      }}>{`user management`}</a>{` and retrieving a delegation token (more info on each specific section of `}<a parentName="p" {...{
        "href": "/api/authorization/autonomous#request-a-delegation-token"
      }}>{`autonomous authentication`}</a>{` and `}<a parentName="p" {...{
        "href": "/api/authorization/pin#request-a-delegation-token"
      }}>{`pin based authentication`}</a>{`), however, you will instead use the client token.`}</p>
    <br />
    <br />
    <h2 {...{
      "id": "the-client-token"
    }}>{`The client token`}</h2>
    <hr style={{
      "width": "50%"
    }} />
    <p>{`Used to retrieve the delegation token and some account related endpoints where you don't need to be logged in to perform the request.`}<br />{`
This is a temporary token which can be used by an application to perform administrative tasks.`}<br /><br />{`
To request a client token, you will need a self-signed token that uniquely identifies your application so that the SmartVault service can recognize it.
After redeeming your self-signed token, SmartVault will hand you the client token and an expiration (generally 24 hours).`}<br /><br />{`
Once you are in possession of a client token, you can use it to log on as your client program.
You can also exchange this token for a delegation token.`}<br /></p>
    <InfoIcon mdxType="InfoIcon" />
Client tokens will be unique to a user and should be stored securely.
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "the-delegation-token"
    }}>{`The delegation token`}</h2>
    <hr style={{
      "width": "50%"
    }} />
    <p>{`Is the token that "knows" that you are logged in, allowing you to access the account.`}<br />{`
Delegation tokens grant an application access to perform operations on behalf of a SmartVault user.`}<br /><br />{`
You can obtain a delegation token by redeeming the client token you receive after the user authorizes your application.
If the request is successful, you will receive the token, the expiration in seconds (delegation tokens have a lifetime of 14 days), and the user ID.`}</p>
    <p>{`If a call is made to SmartVault’s API with an invalid token, the response will be a 400 status code and it will include an "invalid_token" error.`}<br /></p>
    <InfoIcon mdxType="InfoIcon" />
Remember that the delegation token will not work if the user has revoked an application's access or if it has expired.
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "the-authorization-header"
    }}>{`The authorization header`}</h2>
    <hr style={{
      "width": "50%"
    }} />
    <p>{`With the exception of the authentication steps, you will need to provide an "Authorization" HTTP header containing the word "Basic " followed by the Base64 representation of the user's email, a colon, and the token you received after authenticating.`}<br /><br />{`
Let's assume the user's email address is `}<inlineCode parentName="p">{`testuser@smartvault.com`}</inlineCode>{` and the token received is `}<inlineCode parentName="p">{`Q0xJMDAAAAAAAAABUYDOL8O67z2B7oVbKs+V2x2nfGMx3GascjMPJxcGFxvOyg==`}</inlineCode>{`.`}<br /><br />{`
Build a string as stated above using the user, a colon and the delegation token like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`testuser@smartvault.com:Q0xJMDAAAAAAAAABUYDOL8O67z2B7oVbKs+V2x2nfGMx3GascjMPJxcGFxvOyg==
`}</code></pre>
    <p>{`Then, get the `}<inlineCode parentName="p">{`Base64`}</inlineCode>{` representation of it and that would be the token for the "Authorization" request header. e.g.:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`dGVzdHVzZXJAc21hcnR2YXVsdC5jb206UTB4Sk1EQUFBQUFBQUFBQlVZRE9MOE82N3oyQjdvVmJLcytWMngybmZHTXgzR2FzY2pNUEp4Y0dGeHZPeWc9PQ==
`}</code></pre>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      